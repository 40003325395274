import { window } from "browser-monads"
import React, { useContext, useEffect, useState } from "react"
import { CourseDetailPage as STRLCourseDetailPage } from "skillstrainer-resource-library"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { CourseContext } from "../context/Course"
import { NavigationContext } from "../context/Navigation"
import courseSvc from "../services/Course"
import { toIndianNumber } from "../utils/object"
import { getWebappRouteActionURL, WEBAPP_ROUTE_ACTIONS } from "../utils/request"
import { s3BaseUrl } from "../../config"

const { partner } = require("../data.json")

function CourseDetailPage(props) {
  const [course, setCourse] = useState(props.pageContext.course)
  const [courseModules, setCourseModules] = useState([])
  const { setPath, currentLanguage: lang } = useContext(NavigationContext)
  const { fetchCourseUsers } = useContext(CourseContext)

  useEffect(() => {
    window.scrollTop = 0
    ;(async () => {
      await fetchCourseUsers(course.courseId).then(usersCount => {
        course.students_enrolled = toIndianNumber(usersCount)
      })
      await courseSvc
        .getCourseDemoBatchSlotsAvailability(course.courseId)
        .then(res => {
          if (res) course.isDemoAvailable = true
        })

      setCourse(course)
      if (!course.isMoodleCourse)
        await courseSvc
          .getCourseModulesForNonMoodleCourse(course.courseId)
          .then(res => {
            setCourseModules(res)
          })
    })()
  }, [])

  const goToCategoryPage = () => setPath(`/courses/${course.categoryId}`)
  const multiLangKey = `page.course.${course.courseId}.${lang}`
  return (
    <Layout>
      <Seo title="Course Detail Page" />
      <STRLCourseDetailPage
        courseData={{
          ...course,
          partners:
            course?.partners.length !== 0
              ? [
                  {
                    logo: course.partners[0].logo.includes(s3BaseUrl)
                      ? course.partners[0].logo.replace(s3BaseUrl + "/", "")
                      : course.partners[0].logo,
                    id: course.partners[0].id,
                  },
                ]
              : [],
          onBookDemo: () => {
            if (course.isDemoAvailable)
              window.open(
                getWebappRouteActionURL(WEBAPP_ROUTE_ACTIONS.book_demo_course, {
                  courseId: course.courseId,
                }),
                "_blank"
              )
          },
        }}
        multiLangKey={multiLangKey}
        goToCategoryPage={goToCategoryPage}
        payNow={() => {
          window.open(
            getWebappRouteActionURL(WEBAPP_ROUTE_ACTIONS.purchase_course, {
              courseId: course.courseId,
              partnerId: partner?.id,
            }),
            "_blank"
          )
          return Promise.resolve(false)
        }}
        courseModuleTopic={courseModules}
      />
    </Layout>
  )
}

export default CourseDetailPage
